.header-height {
  height: 80px; /* Ensure the content starts below the fixed header */
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav li {
  display: inline;
  margin: 0 10px;
}

main {
  padding: 20px;
  text-align: center;
}
