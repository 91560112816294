.about-us-section {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-collage {
  width: 40%;
  position: relative;
  height: 500px; /* Adjust based on image size */
}

.collage-image {
  position: absolute;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.collage-image-1 {
  background-image: url("../images/intro_1.jpg");
  width: 220px; /* Square image */
  height: 220px;
  top: 0;
  left: 20px;
  transform: rotate(-5deg);
}

.collage-image-2 {
  background-image: url("../images/intro_2.jpg");
  width: 240px; /* Slightly larger rectangle */
  height: 180px;
  top: 180px;
  left: 40px;
  transform: rotate(3deg);
  z-index: -1;
}

.collage-image-3 {
  background-image: url("../images/intro_3.jpg");
  width: 210px; /* Smaller rectangle */
  height: 160px;
  top: 340px;
  left: 10px;
  transform: rotate(-4deg);
  z-index: -2;
}

.about-us-info {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-item {
  display: flex;
  align-items: center;
}

.info-icon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
  filter: brightness(0) saturate(100%) invert(37%) sepia(95%) saturate(3009%)
    hue-rotate(204deg) brightness(104%) contrast(103%);
  opacity: 0.75;
}

.info-text {
  display: flex;
  flex-direction: column;
}

.info-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.info-description {
  font-size: 16px;
  color: #555;
}

@media (max-width: 768px) {
  .about-us-section {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .about-us-collage {
    width: 80%;
    height: auto;
    position: relative;
    margin-bottom: 40px;
    display: flex;
    justify-content: center; /* Center the collage horizontally */
  }

  .collage-image {
    position: static; /* Removes absolute positioning to avoid overlap */
    margin-bottom: 20px;
  }

  .about-us-info {
    width: 80%;
    align-items: center;
  }

  .info-item {
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .info-icon {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .info-text {
    align-items: center;
  }
}
