.menu-link {
  position: relative;
  display: inline-block;
}

.menu-link.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: rgba(49, 57, 79, 1);
  transform: translateX(-50%);
  transform-origin: center;
  animation: underline-expand-center 0.3s forwards;
}

@keyframes underline-expand-center {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
